import { useState, useEffect } from 'react';
import './Content.css';
import moment from 'moment';
import { getWinnerNamesPerMonth, getPromoByPerWebsites } from "../api/bonusAPI";
import Next from './navigate_next_black_24dp.svg';
import web from './website.png';
import phone from './phone.png';
import telegram from './telegram.png';
import wa from './whatsapp.png';

function Monthlybonus() {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const [promos, setPromos] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [winners, setWinners] = useState([]);


  useEffect(() => {
    const fetchPromosAndWinners = async () => {
      try {
        const response = await getPromoByPerWebsites("gudangcuan", currentYear, currentMonth);
        const filteredPromos = response.filter(promo => promo.promoType === '2');
        setPromos(filteredPromos);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPromosAndWinners();
  }, [currentMonth, currentYear]);
  
  

  useEffect(() => {
    const fetchWinners = async () => {
      if (selectedPromo) {
        try {
          const winnersResponse = await getWinnerNamesPerMonth(selectedPromo);
          const groupedByDate = groupWinnersByDate(winnersResponse);
          setWinners(groupedByDate);
        } catch (error) {
          console.error('Failed to fetch winners:', error);
        }
      }
    };
    fetchWinners();
  }, [selectedPromo]);
  
  const groupWinnersByDate = (winnersList) => {
    return winnersList.reduce((acc, winner) => {
      const key = `${winner.wYear}-${winner.wMonth}-${winner.wDate}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(winner);
      return acc;
    }, {});
  };

  const selectedPromoName = promos.find(promo => promo.promoId === selectedPromo)?.promoName;
  const sortedDateKeys = Object.keys(winners).sort((a, b) => moment(b, 'YYYY-M-D').valueOf() - moment(a, 'YYYY-M-D').valueOf());

  return (
    <section id='monthly-bonus' className='container mt-5'>
      <div id="contact-links" className='container mt-5'>
          <div id="trigger"><img className='image-next' alt='next' src={Next}/></div>
          <div id="content">
              <h3 className='contact-us'>
                <img className='img-contact' src="https://www.clipartmax.com/png/small/92-926082_contact-us-contact-us-icon-png.png" alt="Contact Us - Contact Us Icon Png @clipartmax.com"/>
                <span className='text-contact'>CONTACT US</span>
                </h3>
                <br/>
              <a href="https://wa.me/+639981810579" target="_blank" rel="noreferrer">
                <img src={wa} className='wa-img' alt='wa-img'/>
                <span className='wa-text'>Whatsapp</span>
              </a>
              <a href="https://t.me/Gudangcuan_official" target="_blank" rel="noreferrer">
                <img src={telegram} className='tele-img' alt='wa-img'/>
                <span className='tele-text'>Telegram</span>
              </a>
              {/* <a href="https://mpo1221yes.com/tel:Phone" target="_blank" rel="noreferrer">
                <img src={phone} className='ig-img' alt='wa-img'/>
                <span className='phone-text'>Phone</span>
              </a> */}
              <a href="https://gudangcuan.com/" target="_blank" rel="noreferrer">
                <img src={web} className='fb-img' alt='wa-img'/>
                <span className='web-text'>Website</span>
              </a>
          </div>
      </div>
      <h1 className='header-monthly'>Special Bonus</h1>
      <select className='select-month' onChange={(e) => setSelectedPromo(e.target.value)}>
        <option value=''>- Select Special Bonus -</option>
        {promos.map((promo) => (
          <option key={promo.promoId} value={promo.promoId}>{promo.promoName}</option>
        ))}
      </select>
      {sortedDateKeys.map(dateKey => {
        const winnersForDate = winners[dateKey];
        const formattedDate = moment(dateKey, 'YYYY-M-D').format('D MMMM YYYY');
        const rows = winnersForDate.reduce((acc, winner, index) => {
          if (index % 5 === 0) acc.push([]);
          acc[acc.length - 1].push(winner);
          return acc;
        }, []);

        return (
          <div key={dateKey} className="table-monthly">
            <table>
              <thead>
                <tr>
                  <th colSpan={5}>{selectedPromoName} - {formattedDate}</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((winner, index) => {
                      const censoredHTML = winner.loginIdCensored.replace(/\*\*/g, '<span class="text-censored">**</span>');
                      return (
                        <td key={index} dangerouslySetInnerHTML={{ __html: censoredHTML }}></td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </section>
  );
}

export default Monthlybonus;
